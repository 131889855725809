import React from "react";
import { useSelector } from "react-redux";
import {
  LoginForm,
  LoginFormPoed,
  LoginFormPoupas,
  HotelsSearchModal,
  SignInFormPoupas,
  RequestPackageFormModal,
  LoginFormSyks,
} from "../../components/organisms";

export default function ModalManager() {
  const modalLookup: any = {
    // TestModal
    LoginForm,
    LoginFormPoed,
    LoginFormSyks,
    LoginFormPoupas,
    HotelsSearchModal,
    SignInFormPoupas,
    RequestPackageFormModal,
  };
  const currentModal = useSelector((state: any) => state.modals);
  let renderedModal;
  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];
    renderedModal = <ModalComponent {...modalProps} />;
  }

  return <span>{renderedModal}</span>;
}
